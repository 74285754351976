// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
// Highlighting for code blocks
import "prismjs/themes/prism.css"
//
import "./src/components/fonts/baloo-regular-webfont.woff"
import "./src/components/fonts/baloo-regular-webfont.woff2"
import "./src/components/fonts/Baloo-Regular.ttf"
import "./src/components/fonts/stylesheet.css"
